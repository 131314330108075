@use './variables' as *;

@mixin mobile {
  @media only screen and (max-width: $mobile-width) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (max-width: $tablet-width) {
    @content;
  }
}

@mixin lap {
  @media only screen and (max-width: $lap-width) {
    @content;
  }
}
@mixin max {
  @media only screen and (min-width: $max-width) {
    @content;
  }
}
