@use 'src/assets/styles/scss' as *;
.projects {
  margin-top: 5rem;
  padding: 0 3rem;
  max-width: $max-container;
  margin-left: auto;
  margin-right: auto;

  .mySwiper {
    padding-top: 15px;
    .swiper-pagination-progressbar-fill {
      background: var(--title-color) !important;
    }
    .swiper-pagination-progressbar {
      background: var(--bg-opacity) !important;
    }
  }
  @include mobile {
    padding: 0 1.5rem;
  }
  &__title {
    font-size: 2rem;
    margin-bottom: 2rem;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    color: var(--title-color);
  }
  &__content {
    @include flex(stretch, space-between);
    flex-wrap: wrap;
    max-height: 100%;
    :global {
      .swiper-slide {
        padding: 30px;
      }
    }

    &--item {
      // width: 30%;
      height: 100%;
      z-index: 2;
      max-height: 100%;
      font-size: 18px;
      padding: 1rem;
      &_card {
        position: relative;
        padding: 0.5rem;
        background: var(--bg-opacity);
        border-radius: 20px;
        box-shadow: 0 0 15px 5px rgba(131, 131, 131, 0.3);
        box-sizing: border-box;
        transition: box-shadow 0.3s ease;
        height: 100%;
        overflow: hidden;
        @include tablet {
          font-size: 12px;
        }
        &:hover {
          box-shadow: 0 0 15px 7px rgba(131, 131, 131, 0.4);
        }
        &:hover::after {
          opacity: 1;
          animation-name: mask-ani;
        }
        &::after {
          content: '';
          width: 210%;
          height: 170px;
          background-image: linear-gradient(
            to bottom,
            rgba(179, 255, 253, 0) 0%,
            rgba(255, 255, 253, 0.3) 45%,
            rgba(255, 255, 253, 0.3) 55%,
            rgba(179, 255, 253, 0) 100%
          );
          transform-origin: center center;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
          animation-duration: 3s;
          animation-iteration-count: infinite;
          opacity: 0;
        }
      }

      @keyframes mask-ani {
        0% {
          transform: rotate(-36deg) translate(-40%, -430px);
        }
        60%,
        100% {
          transform: rotate(-36deg) translate(-40%, 300%);
        }
      }

      img {
        width: 100%;
        height: 190px;
        border-radius: 10px;
      }
      &_content {
        &-title {
          font-size: 1.2rem;
          font-weight: 700;
          color: var(--title-color);
        }

        &-description {
          font-family: 'Titil', sans-serif;
          margin-bottom: 3rem;
          @include tablet {
            margin-bottom: 3.5rem;
          }
          span {
            font-family: 'Titil-bold', sans-serif;
            color: rgba(34, 149, 249);
            font-weight: 700;
          }
          > section {
            font-size: 0.9rem;
          }
        }
        &-button {
          @include flex(center, space-between);
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          padding: 1rem;

          button,
          a {
            text-align: center;
            font-family: 'Titil', sans-serif;
            width: 47%;
            color: #fff;
            border: none;
            border-radius: 0;
            position: relative;
            transform: translate(0%, 0%);
            border-radius: 20px;
            cursor: pointer;
            padding: 10px 0;
            overflow: hidden;
            z-index: 3;
            &.demo-front-end {
              width: 100%;
            }
            &.full {
              width: 100%;
            }
            &:active {
              transform: translateY(1px);
            }
            &:focus,
            &:active {
              outline: none;
            }
            &:before,
            &:after {
              content: '';
              position: absolute;
              bottom: 0;
              top: 0;
              left: 0;
              right: 0;
              height: auto;
              width: auto;
              z-index: -1;
              border-radius: inherit;
              background: inherit;
            }

            &:before {
              background: linear-gradient(
                90deg,
                #0ebeff,
                #ffdd40,
                #ae63e4,
                #47cf73,
                #0ebeff,
                #ffdd40,
                #ae63e4,
                #47cf73
              );
              background-size: 200% 200%;
            }
            &:after {
              margin: 3px;
            }
            &:hover:before,
            &:focus:before {
              animation: rainbow-border 1.5s linear infinite;
            }

            @keyframes rainbow-border {
              0% {
                background-position: 0% 50%;
              }
              100% {
                background-position: 200% 50%;
              }
            }
            &:first-child {
              background: rgba(251, 0, 46, 0.864);
            }
            &:nth-child(2),
            &.live {
              background: rgba(7, 134, 245, 0.821);
            }
          }
          a {
            padding: 7px 0;
            box-sizing: border-box;
          }
        }
      }
    }
  }
}

.projects__title {
  transition: all 0.5s ease;
}
.hidden.projects {
  .projects__title {
    transform: translateY(40px);
    opacity: 0;
  }
}

.projects__content--item {
  position: relative;
  @for $i from 1 through 15 {
    &:nth-child(#{$i}) {
      z-index: 16 - $i;
      // transition: transform $i/3 + 0.7s ease, opacity 0.5s ease;
    }
  }
  &:nth-child(3n + 1) {
    transition: transform 1.2s ease, opacity 0.5s ease;
  }
  &:nth-child(3n + 2) {
    transition: transform 1.5s ease-out, opacity 0.5s ease;
  }
  &:nth-child(3n + 3) {
    transition: transform 1.7s ease-out, opacity 0.5s ease;
  }
  @include tablet {
    &:nth-child(2n + 1) {
      transition: transform 1.2s ease, opacity 0.5s ease;
    }
    &:nth-child(2n + 2) {
      transition: transform 1.4s ease-out, opacity 0.5s ease;
    }
  }
  @include mobile {
    transition: transform 0.6s ease-out, opacity 0.5s ease;
  }
}

.hidden-project.projects__content--item {
  transform: translateX(-100vw);
  opacity: 0;
}
