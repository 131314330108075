@mixin flex($aligin-item, $justify-content) {
  display: flex;
  align-items: $aligin-item;
  justify-content: $justify-content;
}

@mixin box-shadow-btn {
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.26);
  &:hover {
    box-shadow: 0 1.5px 7px 0 rgba(0, 0, 0, 0.26);
  }
}

@mixin text-line($num) {
  overflow-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $num;
}
