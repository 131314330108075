.header__left--content--title,
.btn__download,
.btn__let-talk,
.dark-light,
.header__left--content--fe {
  transform: translateY(50px);
  opacity: 0;
}
.header__left--content--title {
  animation: moveY 0.7s 0.7s ease forwards;
}
.header__left--content--fe {
  animation: moveY 0.7s 1s ease forwards;
}
.btn__download {
  animation: moveY 0.7s 1.3s ease forwards;
}
.btn__let-talk {
  animation: moveY 0.7s 1.6s ease forwards;
}
.dark-light {
  animation: moveY 0.3s 1.5s ease forwards;
}

@keyframes moveY {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  80% {
    transform: translateY(-10px);
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.header__user-icon {
  transform: translateY(-70px);
  opacity: 0;
  animation: moveY2 0.6s 1.2s forwards;
}

@keyframes moveY2 {
  0% {
    transform: translateY(-70px);
    opacity: 0;
  }
  80% {
    transform: translateY(20px);
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.header__right {
  animation: moveX 1.3s ease-in-out forwards;
  transform: translateX(-100%);
}

@keyframes moveX {
  0% {
    transform: translateX(115%);
  }
  90% {
    transform: translateX(-20px);
  }
  100% {
    transform: translateX(0%);
  }
}
.navbar ul {
  transform-origin: 100% 100%;
  transform: scaleY(0);
  animation: scaleEY 0.5s 1s ease forwards;
}

@keyframes scaleEY {
  0% {
    transform: scaleY(0);
  }
  90% {
    transform: scaleY(1.2);
  }
  100% {
    transform: scaleY(1);
  }
}
.about__left {
  opacity: 0;
  animation: opacityA 0.5s 1.5s ease forwards;
}
@keyframes opacityA {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
