@use './scss/' as *;

@font-face {
  font-family: 'Source';
  src: url('../fonts/SourceCodePro-VariableFont_wght.ttf')
      format('woff2 supports variations'),
    url('../fonts/SourceCodePro-VariableFont_wght.ttf')
      format('woff2-variations');
  font-weight: 100 900;
}
@font-face {
  font-family: 'Titil';
  src: url('../fonts/TitilliumWeb-Regular.ttf')
      format('woff2 supports variations'),
    url('../fonts/TitilliumWeb-Regular.ttf') format('woff2-variations');
  font-weight: 100 900;
}
@font-face {
  font-family: 'Titil-bold';
  src: url('../fonts/TitilliumWeb-Bold.ttf') format('woff2 supports variations'),
    url('../fonts/TitilliumWeb-Bold.ttf') format('woff2-variations');
  font-weight: 100 900;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

html {
  font-size: 120%;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
  @include tablet {
    font-size: 80%;
  }

  @include mobile {
    font-size: 70%;
  }
}

body {
  font-weight: 400;
  line-height: 1.5;
  overflow-x: hidden;
  font-family: 'Source';
  width: 100vw;
  overflow-y: scroll;
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #b5b5b5;
  }

  &::-webkit-scrollbar {
    width: 7px;
    background-color: #b5b5b5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #000000;
    border: 1px solid #b5b5b5;
    border-radius: 5px;
  }
}

button,
input {
  outline: none;
  border: none;
}
a {
  text-decoration: none;
}

ul {
  list-style-type: none;
}

img {
  max-width: 100%;
  object-fit: cover;
}

iframe {
  border: 0;
}
.App {
  width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
  background: var(--body-bg);
  background-image: var(--body-image-bg);
  transition: background 0.5s ease;
  cursor: context-menu;
  color: var(--main-color);

  @include tablet {
    padding: 1em;
  }
  @include mobile {
    padding: 0;
  }
}
.dark-light {
  position: fixed;
  top: 20px;
  right: 20px;
  border: 1px solid var(--theme-color);
  background-color: var(--dropdown-bg);
  box-shadow: -1px 3px 8px -1px rgba(0, 0, 0, 0.2);
  padding: 5px;
  border-radius: 50%;
  z-index: 300;
  cursor: pointer;
  width: 40px;
  height: 40px;
  svg {
    width: 100%;
    flex-shrink: 0;
    fill: #ffce45;
    stroke: #ffce45;
    transition: 0.5s;
  }
}

.light-mode {
  .dark-light {
    svg {
      fill: transparent;
      stroke: var(--theme-color);
    }
  }
}

.line-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: hidden;
}

svg {
  display: inline-block;
  height: 100%;
  fill: none;
}

.cursor {
  position: fixed;
  top: 0;
  left: -100px;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  mix-blend-mode: exclusion;
  background: #fff;
  pointer-events: none;
  z-index: 999;
  transition: transform 0.2s ease, left 0.05s ease-in, top 0.05s ease-in;
  @include flex(center, center);
  @media screen and (max-width: $lap-width) and (min-height: 1250px) {
    display: none;
  }
  @include tablet {
    display: none;
  }
  &.small {
    transform: scale(4);
  }
  > div {
    transform: scale(0);
    width: 100%;
    height: 100%;
    background: #000000;
    border-radius: 50%;
    transition: transform 0.1s ease;
  }
  &.expand {
    > div {
      transform: scale(1);
    }
  }
}
