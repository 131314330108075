@use 'src/assets/styles/scss' as *;

.contact {
  margin-top: 5rem;
  margin-bottom: 4.5rem;
  padding: 0 3rem;
  width: 100%;
  max-width: $max-container;
  margin-left: auto;
  margin-right: auto;
  @include flex(stretch, space-between);
  flex-wrap: wrap;
  position: relative;
  transform-style: preserve-3d;
  @include mobile {
    padding: 0 1.5rem;
    margin-bottom: 5rem;
  }
  &__background-plance {
    position: absolute;
    right: -5%;
    top: -20%;
    width: 40vw;
    z-index: 1;
    @include mobile {
      width: 60vw;
      right: -15%;
      top: -5%;
    }
    img {
      transform: perspective(2000px) rotate(0deg);
      animation: rotateImg 40s infinite forwards;
      width: 100%;
      @keyframes rotateImg {
        0% {
          transform: perspective(2000px) rotate(0deg);
        }
        50% {
          transform: perspective(2000px) rotate(-180);
        }
        100% {
          transform: perspective(2000px) rotate(-360deg);
        }
      }
    }
  }
  &__title {
    width: 100%;
    position: relative;
    z-index: 2;
    font-size: 2.5rem;
    margin-bottom: 2.5rem;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    color: var(--title-color);
    mix-blend-mode: difference;
  }
  &__left {
    width: 35%;
    background: var(--bg-opacity);
    padding: 1rem;
    box-shadow: 0 0 20px 4px rgba(131, 131, 131, 0.5);
    border-radius: 10px;
    position: relative;
    z-index: 2;
    @include lap {
      width: 40%;
    }
    @include mobile {
      width: 100%;
      margin-bottom: 4rem;
      padding-bottom: 0;
    }
    &--title {
      font-weight: 700;
      font-size: 1.7rem;
      text-align: center;
      margin-bottom: 1rem;
    }
    &--content {
      padding: 0 1rem 1rem 1rem;

      &_item {
        margin-bottom: 1.5rem;
        padding-bottom: 1.5rem;
        position: relative;
        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 0.5px;
          background: var(--main-color);
        }
        &:last-child {
          padding-bottom: 0;
          &::before {
            width: 0;
            background: none;
          }
        }
        h4 {
          @include flex(center, none);
          svg,
          .message-icon {
            margin-right: 0.3rem;
            width: 1.5rem;
            fill: var(--main-color);
          }
        }
        article {
          padding-left: 1.8rem;
          a {
            color: var(--link-color);
          }
        }
      }
    }
  }
  &__right {
    position: relative;
    z-index: 2;
    border-radius: 10px;
    padding: 1rem;
    width: 60%;
    background: var(--bg-opacity);
    box-shadow: 0 0 20px 4px rgba(131, 131, 131, 0.5);
    @include lap {
      width: 55%;
    }
    @include mobile {
      width: 100%;
    }
    &--title {
      font-weight: 700;
      font-size: 1.7rem;
      text-align: center;
      margin-bottom: 1rem;
    }
    form {
      width: 100%;
      padding: 0 1rem;
      section {
        width: 100%;
        margin-bottom: 1rem;
        label {
          span {
            font-weight: 700;
          }
          input {
            margin-top: 0.2rem;
            padding: 1rem;
            width: 100%;
            background: none;
            color: var(--main-color);
            border: 1px solid var(--boder-color);
            transition: border 0.5s ease;
            border-radius: 7px;
            &:focus,
            &:active {
              border-color: rgba(0, 110, 207, 0.821);
            }
          }
          textarea {
            border-radius: 7px;
            margin-top: 0.2rem;
            width: 100%;
            min-height: 150px;
            background: none;
            color: var(--main-color);
            outline: none;
            padding: 1rem;
            border: 1px solid var(--boder-color);
            transition: border 0.5s ease;
            &:focus,
            &:active {
              border-color: rgba(0, 110, 207, 0.821);
            }
          }
        }
      }
      .button-submit {
        text-align: right;
        button {
          padding: 10px 20px;
          background: rgba(0, 110, 207, 0.821);

          color: #fff;
          position: relative;
          transform: translate(0%, 0%);
          cursor: pointer;
          overflow: hidden;
          z-index: 3;
          &:active {
            transform: translateY(1px);
          }
          &:focus,
          &:active {
            outline: none;
          }
          &:before,
          &:after {
            content: '';
            position: absolute;
            bottom: 0;
            top: 0;
            left: 0;
            right: 0;
            height: auto;
            width: auto;
            z-index: -1;
            border-radius: inherit;
            background: inherit;
          }
          &:before {
            background: linear-gradient(
              90deg,
              #0ebeff,
              #ffdd40,
              #ae63e4,
              #47cf73,
              #0ebeff,
              #ffdd40,
              #ae63e4,
              #47cf73
            );
            background-size: 200% 200%;
          }
          &:after {
            margin: 3px;
          }
          &:hover:before,
          &:focus:before {
            animation: rainbow-border 1.5s linear infinite;
          }

          @keyframes rainbow-border {
            0% {
              background-position: 0% 50%;
            }
            100% {
              background-position: 200% 50%;
            }
          }
        }
      }
    }
  }
  &__background-bottom {
    position: absolute;
    bottom: 0vh;
    right: 6%;
    text-align: center;
    // width: 100%;
    img {
      width: 40vw;
      @include mobile {
        width: 75vw;
      }
    }
  }
}

.copy-right {
  width: 100%;
  text-align: center;
  margin-top: 5rem;
  position: relative;
  padding: 1.5rem;
  z-index: 1;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: 90%;
    height: 1px;
    background: linear-gradient(45deg, #12c2e9, #c471ed, #f64f59);
  }
  &_title {
    font-size: 2rem;
    font-weight: 600;
    color: var(--title-color);
    margin-bottom: 1rem;
  }
  > section {
    font-size: 16px;
    @include flex(center, center);
    @include mobile {
      font-size: 12px;
    }
    svg {
      width: 13px;
      margin: 0px 10px;
      color: #ff3838;
    }
  }
}

.contact__title {
  transition: all 0.5s ease;
}

.contact__left--title,
.contact__right--title {
  transition: all 0.5s 0.3s ease;
}
.contact__left--content_item {
  &:nth-child(1) {
    transition: all 0.5s 0.5s ease;
  }
  &:nth-child(2) {
    transition: all 0.5s 1s ease;
  }
  &:nth-child(3) {
    transition: all 0.5s 1.5s ease;
  }
}

.contact__right form section {
  &:nth-child(1) {
    transition: all 0.5s 0.5s ease;
  }
  &:nth-child(2) {
    transition: all 0.5s 1s ease;
  }
  &:nth-child(3) {
    transition: all 0.5s 1.5s ease;
  }
}
.button-submit {
  transition: all 0.5s 2s ease;
}
.hidden.contact {
  .button-submit,
  .contact__right form section,
  .contact__left--content_item,
  .contact__right--title,
  .contact__left--title,
  .contact__title {
    transform: translateY(40px);
    opacity: 0;
  }
}
